import React from 'react';
import { Link } from 'gatsby';

import headerStyles from './Header.module.scss';

const Header = () => {
  const { toggler, hamburger, navDrawer, link } = headerStyles;

  return (
    <nav>
      <input type="checkbox" className={toggler} />
      <div className={hamburger}>
        <div></div>
      </div>
      <div className={navDrawer}>
        <ul>
          <li>
            <Link to="/" className={link}>
              home
            </Link>
          </li>
          <li>
            <Link to="/#work" className={link}>
              work
            </Link>
          </li>
          <li>
            <Link to="/blog" className={link}>
              blog
            </Link>
          </li>
          <li>
            <Link to="/about" className={link}>
              about
            </Link>
          </li>
          <li>
            <Link to="/#contact" className={link}>
              contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
