import React from 'react';
import { Link } from 'gatsby';

import blogLayoutStyles from './BlogLayout.module.scss';

const BlogLayout = props => {
  const { pageWrap, contentWrap, blogHeader } = blogLayoutStyles;

  return (
    <div className={pageWrap}>
      <div className={contentWrap}>
        <header className={blogHeader}>
          <h1>
            <Link to="/">jonathan viray</Link> / <Link to="/blog">blog</Link>
          </h1>
          <p>things I feel like sharing.</p>
        </header>
        {props.children}
      </div>
    </div>
  );
};

export default BlogLayout;
