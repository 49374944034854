import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './Header';
import '../../styles/main.scss';

// Require and attach the smooth-scroll library to...
// ...any anchor tag containing a hashtag.
if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]');
}

const Layout = props => {
  return (
    <div>
      <Helmet>
        <link
          rel="icon"
          href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🤘🏽</text></svg>"
        />

        <title>Jonathan Viray</title>
      </Helmet>
      <Header />
      {props.children}
    </div>
  );
};

export default Layout;
